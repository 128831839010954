var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-row", [
    _vm.name
      ? _c("div", { staticClass: "col-md-12" }, [
          _vm._v("\n    " + _vm._s(_vm.name) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-2" },
      [
        _c("base-select", {
          attrs: {
            id: _vm.conf.id,
            vid: "select" + _vm.conf.id,
            name: "type",
            label: "Compagnia Coass",
            options: _vm.conf.options,
            disabled: _vm.disableCoass,
          },
          on: { select: _vm.onSelect, remove: _vm.onRemove },
          model: {
            value: _vm.inputVal.type,
            callback: function ($$v) {
              _vm.$set(_vm.inputVal, "type", $$v)
            },
            expression: "inputVal.type",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-2" },
      [
        _c("base-currency", {
          attrs: {
            id: "quota-" + _vm.conf.id,
            vid: "quota" + _vm.conf.id,
            name: "quota",
            label: "Quota",
            options: {
              locale: "it-IT",
              currencyDisplay: "percent",
              precision: 2,
            },
            placeholder: "... quota",
            readonly: !_vm.inputVal.type || _vm.disableShare,
            rules: _vm.inputVal.type ? _vm.rules : {},
          },
          model: {
            value: _vm.inputVal.share,
            callback: function ($$v) {
              _vm.$set(_vm.inputVal, "share", $$v)
            },
            expression: "inputVal.share",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.hideAmounts
      ? _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c("base-currency", {
              attrs: {
                id: "net-" + _vm.conf.id,
                vid: "net" + _vm.conf.id,
                name: "net",
                label: "Netto",
                options: { currency: "EUR", locale: "it-IT" },
                placeholder: "... netto",
                readonly: !_vm.inputVal.type || _vm.disableAmounts,
                rules: _vm.inputVal.type ? _vm.rules : {},
              },
              model: {
                value: _vm.inputVal.net,
                callback: function ($$v) {
                  _vm.$set(_vm.inputVal, "net", $$v)
                },
                expression: "inputVal.net",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hideAmounts
      ? _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c("base-currency", {
              attrs: {
                id: "tax-" + _vm.conf.id,
                vid: "tax" + _vm.conf.id,
                name: "tax",
                label: "Tasse",
                options: { currency: "EUR", locale: "it-IT" },
                placeholder: "... tasse",
                readonly: !_vm.inputVal.type || _vm.disableAmounts,
                rules: _vm.inputVal.type ? _vm.rules : {},
              },
              model: {
                value: _vm.inputVal.tax,
                callback: function ($$v) {
                  _vm.$set(_vm.inputVal, "tax", $$v)
                },
                expression: "inputVal.tax",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hideAmounts
      ? _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c("base-currency", {
              attrs: {
                id: "gross-" + _vm.conf.id,
                vid: "gross" + _vm.conf.id,
                name: "gross",
                label: "Lordo",
                value: _vm.inputVal.net + _vm.inputVal.tax,
                options: { currency: "EUR", locale: "it-IT" },
                readonly: true,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.inputVal.type && _vm.canDelete
      ? _c(
          "div",
          { staticClass: "form-group col-md-2 align-self-end" },
          [
            _c(
              "b-button",
              {
                staticClass: "mt-1 mr-1",
                attrs: { size: "sm", variant: "lisaweb", title: "Rimuovi" },
                on: {
                  click: function ($event) {
                    return _vm.onDelete(_vm.conf.id)
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "trash" } })],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }