var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3" },
    _vm._l(_vm.details, function (obj, index) {
      return _c(
        "div",
        { key: "" + index + obj.id },
        [
          _vm.inputVal.coass.coass_details[obj.id]
            ? _c("coass-detail", {
                attrs: {
                  name: _vm.noName ? "" : _vm.name + " " + (index + 1),
                  conf: obj,
                  canDelete: _vm.canDelete,
                  disableAmounts: _vm.disableAmounts,
                  hideAmounts: _vm.hideAmounts,
                  disableCoass: _vm.disableCoass,
                  disableShare: _vm.disableShare,
                  rules: _vm.rules,
                },
                on: {
                  select: _vm.onSelectCoassDetail,
                  unselect: _vm.onUnselectCoassDetail,
                  delete: _vm.onDeleteCoassDetail,
                },
                model: {
                  value: _vm.inputVal.coass.coass_details[obj.id],
                  callback: function ($$v) {
                    _vm.$set(_vm.inputVal.coass.coass_details, obj.id, $$v)
                  },
                  expression: "inputVal.coass.coass_details[obj.id]",
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }